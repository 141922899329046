import React from 'react'
import tw from 'twin.macro'
import { Spacer } from '@elements'
import { useStateContext } from '@context/stateContext'
import { Contact } from '@modules'

const ContactPage = () => {
  const {
    content: {
      pages: { contact },
    },
  } = useStateContext()

  return (
    <Container>
      <Spacer className="pt-10 2xl:pt-16" />
      <Contact contact={contact} />
    </Container>
  )
}

export default ContactPage

const Container = tw.div`w-full bg-background overflow-x-hidden`
